const Link = {
  variants: {
    default: {
      color: 'blue.700',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
}

export default Link
