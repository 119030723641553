const colors = {
  //可以這樣寫純色的 value
  black: '#000',
  white: '#fff',
  //系列色可以再用物件傳入色號數值
  gray: {
    900: '#262626',
    800: '#323232',
    700: '#434343',
    600: '#595959',
    500: '#8c8c8c',
    400: '#BFBFBF',
    300: '#d9d9d9',
    200: '#f0f0f0',
    100: '#f5f5f5',
    50: '#fafafa',
  },
  blue: {
    700: '#093D4F',
    600: '#0057C1',
    500: '#1F6A94',
    400: '#6F9CC9',
    300: '#8FB5E1',
  },
  red: {
    700: '#CF1322',
    600: '#F5222D',
    500: '#FF4D4F',
    400: '#FF7875',
    300: '#FFA39E',
  },
  orange: {
    700: '#D46B08',
    600: '#FA8C16',
    500: '#FFA940',
    400: '#FFC069',
    300: '#FFD591',
  },
};

export default colors;
